const Obj = {
  workShift: {
    id: 1,
    pointId: 2,
    peopleId: 11,
    jobId: 3,
    rate: 200,
    order: 0,
    date: "25.02.2024",
    status: "closed",
    hourBegin: 7,
    hourEnd: 15,
    minuteBegin: 0,
    minuteEnd: 0,
    utimeBegin: 1714536000,
    utimeEnd: 1714564800,
    timeAdd: 0,
    timeAddNote: "",
    jobIcon: "⭐️",
    orderAbs: 0,
    caption: "Хрипуня",
  },
  employee: {
    id: 11,
    nickname: "Полина",
    name: "Полина",
    surname: "Исаева",
    patronymic: "Валерьевна",
    jobId: 3,
    jobName: "Стажер",
    status: "fired",
  },
  job: {
    id: 3,
    jobName: "Стажер",
    rate: 0,
    icon: null,
  },
};

/**
 * @typedef {Object} DayData
 * @property {number} id
 * @property {number} date
 * @property {number[]} timeData
 */

/**
 * @typedef {Object} WorkShift
 * @property {number} id
 * @property {number} pointId
 * @property {Employee} employee
 * @property {Job} job
 * @property {string} date
 * @property {number} hourBegin
 * @property {number} hourEnd
 * @property {number} minuteBegin
 * @property {number} minuteEnd
 * @property {number} timeAdd
 * @property {string} timeAddNote
 * @property {number} utimeBegin
 * @property {number} utimeEnd
 * @property {number} orderAbs
 * @property {string} status
 */

/**
 * @typedef {Object} Employee
 * @property {number} id
 * @property {string} nickname
 * @property {string} name
 * @property {string} surname
 * @property {string} patronymic
 * @property {number} jobId
 * @property {string} jobName
 * @property {string} status
 */

/**
 * @typedef {Object} Job
 * @property {number} id
 * @property {string} jobName
 * @property {number} rate
 * @property {null|Object} icon
 */

export const TIME_TYPE = {
  BEGIN: 1,
  END: 2,
};
