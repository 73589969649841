<template>
  <div class="base-switch">
    <div class="base-switch__label">{{ label }}</div>
    <input :checked="value" type="checkbox" @click="changeSelect" />
  </div>
</template>

<script setup>
import { ref } from "vue";

const props = defineProps({ label: { type: String, default: "" }, value: { type: Boolean, default: false } });
const emits = defineEmits(["showAdditionalTime"]);
const showAdditionalTime = ref(props.value);

// todo переписать на универсальный компонент
const changeSelect = () => {
  showAdditionalTime.value = !showAdditionalTime.value;
  emits("showAdditionalTime", showAdditionalTime);
};
</script>

<style lang="scss">
.base-switch {
  display: flex;
  justify-content: space-between;
  input[type="checkbox"] {
    width: 44px;
    height: 24px;
    -webkit-appearance: none;
    outline: none;
    background: var(--color-neutral-300);
    border-radius: 15px;
  }
  input:checked[type="checkbox"] {
    background: var(--color-primary-main);
  }
  input[type="checkbox"]:before {
    content: "";
    position: relative;
    display: block;
    width: 20px;
    height: 20px;
    border-radius: 20px;
    top: 2px;
    left: 2px;
    background: var(--color-basic-white);
  }
  input:checked[type="checkbox"]:before {
    left: 22px;
  }
}
</style>
