const CHESSTABLE_BORDER_WIDTH = 6;
const COLORS_HIGHLIGHT = [
  // "#afb194",
  // "#bf7f3f",
  // "#b363e2",
  // "#b6b844",
  // "#484acb",
  // "#58bfb7",
  // "#e93e52",
  "#FFD1DC",
  "#EFA94A",
  "#7FB5B5",
  "#5D9B9B",
  "#77DD77",
  "#FF8C69",
  "#FF9BAA",
  "#FFB28B",
  "#FCE883",
  "#BEBD7F",
  "#C6DF90",
  "#99FF99",
  "#E6E6FA",
  "#FFF0F5",
];
const COLORS_HIGHLIGHT_USAGE = [];
const TURN_OWN_COLOR = "#193958";

export { CHESSTABLE_BORDER_WIDTH, COLORS_HIGHLIGHT, COLORS_HIGHLIGHT_USAGE, TURN_OWN_COLOR };
